import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import IdentityVerification from 'modules/loan-application/ecg-toolbox/identity-verification/index'
import FundingInfo from 'modules/loan-application/ecg-toolbox/funding-info'
import ChangeOrderRequest from 'modules/loan-application/ecg-toolbox/change-order-request'

import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT } from 'common/constants'

import {
  downloadAllFiles,
  reEvaluateLoanApplication,
  incrementReEvaluationCount,
  extendLoanApplicationExpiration,
  expireLoanApplication,
  downloadAllDeletedAttachments,
} from 'modules/loan-application/actions'
import { STATES_ORDER } from 'modules/loan-application/constants'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import { CLAIMS } from 'common/claims'
import { AccessWrapper, hasAccess } from 'common/access'
import { useStore } from 'store'

import PropertyTitle from './property-title/index'
import Notes from './notes/index'
import CreditReports from './credit-reports/index'
import IncomeEstimator from './income-estimator/index'
import Support from './support'
import HistoryECG from './history'
import Faq from './faq'
import EcgActions from './actions'

import './index.scss'

const { Dialog, Dropdown, IconButton } = components
const { XCloseIcon } = assets

const {
  PresentationChartIcon,
  BarChartSquareUpIcon,
  BankIcon,
  MailIcon,
  CoinsStackedIcon,
  ClockRewindIcon,
  FileShieldIcon,
  FileCheck01Icon,
  MessageChatCircleIcon,
  ToolIcon,
  MessageQuestionCircleIcon,
  SwitchHorizontalIcon,
} = assets

const EcgToolbox = ({
  loanApplicationId,
  loanFormData,
  currentState,
  dispatch,
  isCommercialApp,
  setIsEcgModalOpen,
}) => {
  const { t: translate } = useTranslation()
  const { state } = useStore()

  const [loading, setLoading] = useState({
    downloadAll: false,
    reEvaluate: false,
    extendExpiration: false,
    expireApplication: false,
  })

  const ECG_VIEWS = {
    NOTES: 'notes',
    HISTORY_TRACKING: 'historyTracking',
    IDENTITY_VERIFICATION: 'identityVerification',
    FUNDING: 'funding',
    PROPERTY_TITLE: 'propertyTitle',
    CREDIT_REPORTS: 'creditReports',
    EMAIL_HISTORY: 'emailHistory',
    CHANGE_ORDER: 'changeOrder',
    SUPPORT: 'support',
    FAQ: 'faq',
    INCOME_ESTIMATOR: 'incomeEstimator',
    ACTIONS: 'actions',
  }

  const ECG_VIEWS_FOR_DROPDOWN = [
    {
      id: ECG_VIEWS.CREDIT_REPORTS,
      label: translate('loanApplication.ecgToolbox.sidebar.creditReports'),
      claims: CLAIMS.CAN_VIEW_CREDIT_REPORTS,
    },
    {
      id: ECG_VIEWS.PROPERTY_TITLE,
      label: translate(
        'loanApplication.ecgToolbox.sidebar.propertyTitleReport'
      ),
      claims: CLAIMS.CAN_VIEW_PROPERTY_TITLE,
    },
    {
      id: ECG_VIEWS.FUNDING,
      label: translate('loanApplication.ecgToolbox.sidebar.fundingInfo'),
      claims: [CLAIMS.CAN_VIEW_LOAN_FUNDING, CLAIMS.CAN_EDIT_LOAN_FUNDING],
    },
    {
      id: ECG_VIEWS.EMAIL_HISTORY,
      label: translate('loanApplication.ecgToolbox.sidebar.emailHistory'),
      claims: CLAIMS.CAN_VIEW_LOAN_APPLICATION_EMAILS_HISTORY,
    },
    {
      id: ECG_VIEWS.INCOME_ESTIMATOR,
      label: translate('loanApplication.ecgToolbox.sidebar.incomeEstimator'),
      claims: CLAIMS.CAN_VIEW_INCOME_ESTIMATOR,
    },
    {
      id: ECG_VIEWS.HISTORY_TRACKING,
      label: translate('loanApplication.ecgToolbox.sidebar.historyTracking'),
      claims: CLAIMS.CAN_VIEW_LOAN_APPLICATION_HISTORY,
    },
    {
      id: ECG_VIEWS.IDENTITY_VERIFICATION,
      label: translate(
        'loanApplication.ecgToolbox.sidebar.identityVerification'
      ),
      claims: CLAIMS.CAN_VIEW_IDENTITY_INFORMATION,
    },
    {
      id: ECG_VIEWS.CHANGE_ORDER,
      label: translate(
        'loanApplication.ecgToolbox.changeOrderRequestModal.title'
      ),
      claims: CLAIMS.CAN_REQUEST_NTP_CHANGE_ORDER,
    },
    {
      id: ECG_VIEWS.NOTES,
      label: translate('loanApplication.ecgToolbox.sidebar.notes'),
      claims: CLAIMS.CAN_VIEW_LOAN_APPLICATION_NOTES,
    },
    {
      id: ECG_VIEWS.SUPPORT,
      label: translate('loanApplication.ecgToolbox.support.supportTitle'),
      claims: false,
    },
    {
      id: ECG_VIEWS.FAQ,
      label: translate('loanApplication.ecgToolbox.faq'),
      claims: false,
    },
    {
      id: ECG_VIEWS.ACTIONS,
      label: 'Actions',
      claims: CLAIMS.CAN_VIEW_TOOLBOX_ACTIONS,
    },
  ]

  const filteredComponents = ECG_VIEWS_FOR_DROPDOWN.filter((component) =>
    component.claims
      ? hasAccess(state.session.userData, component.claims)
      : true
  )

  const [ecgValue, setEcgValue] = useState(ECG_VIEWS.SUPPORT)
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)

  const isIncomeEstimatorDisabled = !loanFormData.selectedLoanProduct
  const isReEvaluateDisabled =
    !isCommercialApp &&
    STATES_ORDER[currentState] >= STATES_ORDER.NTPFundsRequested

  const handleReEvaluateLoanApp = () => {
    setLoading({ ...loading, reEvaluate: true })
    reEvaluateLoanApplication({
      loanApplicationId: loanApplicationId,
      commandType: 0,
    }).then(() => {
      incrementReEvaluationCount(dispatch)

      setLoading({ ...loading, reEvaluate: false })
    })
  }

  const extendLoanApplicationExpirationDate = () => {
    setLoading({ ...loading, extendExpiration: true })
    extendLoanApplicationExpiration(
      {
        loanApplicationId: loanApplicationId,
        commandType: 0,
      },
      dispatch
    ).finally(() => {
      setLoading({ ...loading, extendExpiration: false })
    })
  }

  const expireApplication = () => {
    setLoading({ ...loading, expireApplication: true })
    expireLoanApplication(
      {
        loanApplicationId: loanApplicationId,
        commandType: 0,
      },
      dispatch
    ).finally(() => {
      setLoading({ ...loading, expireApplication: false })
    })
  }

  return (
    <Dialog
      title="Toolbox"
      titleProps={{
        sx: {
          display: !isTabletView ? 'none' : 'inherit',
        },
      }}
      contentProps={{
        sx: {
          paddingBottom: !isTabletView ? '0px !important' : 'inherit',
        },
      }}
      open={true}
      onClose={() => setIsEcgModalOpen(false)}
      PaperProps={{
        sx: {
          minWidth: isTabletView ? 'auto' : '1000px',
          maxWidth: isTabletView ? '100%' : '1250px',
          '& .MuiDialogContent-root': {
            padding: isTabletView ? '0 16px 16px' : '0 24px 40px 24px',
            '&::-webkit-scrollbar': {
              display: 'none' /* Hide scrollbar for Webkit browsers */,
            },
            '-ms-overflow-style': 'none' /* Hide scrollbar for IE and Edge */,
            scrollbarWidth: 'none' /* Hide scrollbar for Firefox */,
          },
        },
      }}
    >
      <div
        style={{
          display: !isTabletView ? 'flex' : 'block',
        }}
      >
        <div
          style={{
            borderRight: !isTabletView ? '1px solid #EAECF0' : 'none',
            marginRight: !isTabletView ? '24px' : 0,
          }}
        >
          {!isTabletView ? (
            <div className="toolbox-navigation">
              <AccessWrapper claims={CLAIMS.CAN_VIEW_CREDIT_REPORTS}>
                <div
                  className={cx('toolbox-navigation__item', {
                    'toolbox-navigation__item--selected':
                      ecgValue === ECG_VIEWS.CREDIT_REPORTS,
                  })}
                  onClick={() => setEcgValue(ECG_VIEWS.CREDIT_REPORTS)}
                >
                  <PresentationChartIcon sx={{ fontSize: '20px' }} />
                  {translate(
                    'loanApplication.ecgToolbox.sidebar.creditReports'
                  )}
                </div>
              </AccessWrapper>
              <AccessWrapper claims={CLAIMS.CAN_VIEW_PROPERTY_TITLE}>
                <div
                  className={cx('toolbox-navigation__item', {
                    'toolbox-navigation__item--selected':
                      ecgValue === ECG_VIEWS.PROPERTY_TITLE,
                  })}
                  onClick={() => setEcgValue(ECG_VIEWS.PROPERTY_TITLE)}
                >
                  <BarChartSquareUpIcon sx={{ fontSize: '20px' }} />
                  {translate(
                    'loanApplication.ecgToolbox.sidebar.propertyTitleReport'
                  )}
                </div>
              </AccessWrapper>

              <AccessWrapper
                claims={[
                  CLAIMS.CAN_VIEW_LOAN_FUNDING,
                  CLAIMS.CAN_EDIT_LOAN_FUNDING,
                ]}
              >
                <div
                  className={cx('toolbox-navigation__item', {
                    'toolbox-navigation__item--selected':
                      ecgValue === ECG_VIEWS.FUNDING,
                  })}
                  onClick={() =>
                    CLAIMS.CAN_VIEW_LOAN_FUNDING &&
                    CLAIMS.CAN_EDIT_LOAN_FUNDING &&
                    setEcgValue(ECG_VIEWS.FUNDING)
                  }
                >
                  <BankIcon sx={{ fontSize: '20px' }} />
                  {translate('loanApplication.ecgToolbox.sidebar.fundingInfo')}
                </div>
              </AccessWrapper>

              <AccessWrapper
                claims={CLAIMS.CAN_VIEW_LOAN_APPLICATION_EMAILS_HISTORY}
              >
                <div
                  className={cx('toolbox-navigation__item', {
                    'toolbox-navigation__item--selected':
                      ecgValue === ECG_VIEWS.EMAIL_HISTORY,
                  })}
                  onClick={() => setEcgValue(ECG_VIEWS.EMAIL_HISTORY)}
                >
                  <MailIcon sx={{ fontSize: '20px' }} />
                  {translate('loanApplication.ecgToolbox.sidebar.emailHistory')}
                </div>
              </AccessWrapper>
              <AccessWrapper claims={CLAIMS.CAN_VIEW_INCOME_ESTIMATOR}>
                <div
                  className={cx('toolbox-navigation__item', {
                    'toolbox-navigation__item--selected':
                      ecgValue === ECG_VIEWS.INCOME_ESTIMATOR,
                    disabled: isIncomeEstimatorDisabled,
                  })}
                  onClick={() => setEcgValue(ECG_VIEWS.INCOME_ESTIMATOR)}
                >
                  <CoinsStackedIcon sx={{ fontSize: '20px' }} />
                  {translate(
                    'loanApplication.ecgToolbox.sidebar.incomeEstimator'
                  )}
                </div>
              </AccessWrapper>

              <AccessWrapper claims={CLAIMS.CAN_VIEW_LOAN_APPLICATION_HISTORY}>
                <div
                  className={cx('toolbox-navigation__item', {
                    'toolbox-navigation__item--selected':
                      ecgValue === ECG_VIEWS.HISTORY_TRACKING,
                  })}
                  onClick={() => setEcgValue(ECG_VIEWS.HISTORY_TRACKING)}
                >
                  <ClockRewindIcon sx={{ fontSize: '20px' }} />
                  {translate(
                    'loanApplication.ecgToolbox.sidebar.historyTracking'
                  )}
                </div>
              </AccessWrapper>
              <AccessWrapper claims={CLAIMS.CAN_VIEW_IDENTITY_INFORMATION}>
                <div
                  className={cx('toolbox-navigation__item', {
                    'toolbox-navigation__item--selected':
                      ecgValue === ECG_VIEWS.IDENTITY_VERIFICATION,
                  })}
                  onClick={() => setEcgValue(ECG_VIEWS.IDENTITY_VERIFICATION)}
                >
                  <FileShieldIcon sx={{ fontSize: '20px' }} />
                  {translate(
                    'loanApplication.ecgToolbox.sidebar.identityVerification'
                  )}
                </div>
              </AccessWrapper>
              <AccessWrapper claims={CLAIMS.CAN_VIEW_LOAN_APPLICATION_NOTES}>
                <div
                  className={cx('toolbox-navigation__item', {
                    'toolbox-navigation__item--selected':
                      ecgValue === ECG_VIEWS.NOTES,
                  })}
                  onClick={() => setEcgValue(ECG_VIEWS.NOTES)}
                >
                  <FileCheck01Icon sx={{ fontSize: '20px' }} />
                  {translate('loanApplication.ecgToolbox.sidebar.notes')}
                </div>
              </AccessWrapper>

              <AccessWrapper claims={CLAIMS.CAN_REQUEST_NTP_CHANGE_ORDER}>
                <div
                  className={cx('toolbox-navigation__item', {
                    'toolbox-navigation__item--selected':
                      ecgValue === ECG_VIEWS.CHANGE_ORDER,
                  })}
                  onClick={() => setEcgValue(ECG_VIEWS.CHANGE_ORDER)}
                >
                  <SwitchHorizontalIcon sx={{ fontSize: '20px' }} />
                  {translate(
                    'loanApplication.ecgToolbox.changeOrderRequestModal.title'
                  )}
                </div>
              </AccessWrapper>

              <div
                className={cx('toolbox-navigation__item', {
                  'toolbox-navigation__item--selected':
                    ecgValue === ECG_VIEWS.SUPPORT,
                })}
                onClick={() => setEcgValue(ECG_VIEWS.SUPPORT)}
              >
                <MessageChatCircleIcon sx={{ fontSize: '20px' }} />
                {translate('loanApplication.ecgToolbox.support.supportTitle')}
              </div>

              <div
                className={cx('toolbox-navigation__item', {
                  'toolbox-navigation__item--selected':
                    ecgValue === ECG_VIEWS.FAQ,
                })}
                onClick={() => setEcgValue(ECG_VIEWS.FAQ)}
              >
                <MessageQuestionCircleIcon sx={{ fontSize: '20px' }} />
                {translate('loanApplication.ecgToolbox.faq')}
              </div>

              <AccessWrapper claims={CLAIMS.CAN_VIEW_TOOLBOX_ACTIONS}>
                <div
                  className={cx('toolbox-navigation__item', {
                    'toolbox-navigation__item--selected':
                      ecgValue === ECG_VIEWS.ACTIONS,
                  })}
                  onClick={() => setEcgValue(ECG_VIEWS.ACTIONS)}
                >
                  <ToolIcon sx={{ fontSize: '20px' }} />
                  {'Actions'}
                </div>
              </AccessWrapper>
            </div>
          ) : (
            <Dropdown
              options={filteredComponents}
              value={filteredComponents.find((itm) => itm.id === ecgValue)}
              onChange={(ev) => {
                setEcgValue(ev.target.value.id)
              }}
            />
          )}
        </div>

        <div
          style={{
            flex: 1,
            minWidth: 0,
            paddingBottom: !isTabletView ? '18px' : 0,
          }}
        >
          {!isTabletView && (
            <div className="toolbox-title">
              <span>Toolbox</span>

              <IconButton
                sx={{ marginRight: '-8px' }}
                size="small"
                onClick={() => setIsEcgModalOpen(false)}
              >
                <XCloseIcon />
              </IconButton>
            </div>
          )}
          {ecgValue === ECG_VIEWS.CREDIT_REPORTS && (
            <CreditReports
              isOpen={true}
              loanApplicationId={loanApplicationId}
              loanFormData={loanFormData.borrowerGeneralDetails}
            />
          )}
          {ecgValue === ECG_VIEWS.NOTES && (
            <Notes loanApplicationId={loanApplicationId} />
          )}
          {ecgValue === ECG_VIEWS.IDENTITY_VERIFICATION && (
            <IdentityVerification
              loanApplicationId={loanApplicationId}
              borrower={loanFormData.borrowerGeneralDetails}
              coBorrower={loanFormData.coBorrowerGeneralDetails}
            />
          )}

          {ecgValue === ECG_VIEWS.HISTORY_TRACKING && (
            <HistoryECG loanApplicationId={loanApplicationId} />
          )}

          {ecgValue === ECG_VIEWS.FUNDING && (
            <FundingInfo
              loanApplicationId={loanApplicationId}
              loanFormData={loanFormData}
              finalFundsGranted={
                STATES_ORDER[currentState] >=
                STATES_ORDER.InstallCompleteFundsGranted
              }
              dispatch={dispatch}
            />
          )}
          {ecgValue === ECG_VIEWS.PROPERTY_TITLE && (
            <PropertyTitle
              propertyTitleReportId={loanFormData.propertyTitleReportId}
            />
          )}
          {ecgValue === ECG_VIEWS.INCOME_ESTIMATOR && (
            <IncomeEstimator
              loanApplicationId={loanApplicationId}
              isOpen={true}
            />
          )}
          {ecgValue === ECG_VIEWS.EMAIL_HISTORY && (
            <HistoryECG loanApplicationId={loanApplicationId} isEmailHistory />
          )}
          {ecgValue === ECG_VIEWS.CHANGE_ORDER && (
            <ChangeOrderRequest loanApplicationId={loanApplicationId} />
          )}
          {ecgValue === ECG_VIEWS.SUPPORT && <Support />}
          {ecgValue === ECG_VIEWS.FAQ && <Faq />}
          {ecgValue === ECG_VIEWS.ACTIONS && (
            <EcgActions
              loanApplicationId={loanApplicationId}
              handleReEvaluateLoanApp={handleReEvaluateLoanApp}
              isReEvaluateDisabled={isReEvaluateDisabled}
              isCommercialApp={isCommercialApp}
              downloadAllFiles={downloadAllFiles}
              loading={loading}
              setLoading={setLoading}
              loanFormData={loanFormData}
              extendLoanApplicationExpirationDate={
                extendLoanApplicationExpirationDate
              }
              expireApplication={expireApplication}
              downloadRemovedAttachments={downloadAllDeletedAttachments}
            />
          )}
        </div>
      </div>
    </Dialog>
  )
}

EcgToolbox.propTypes = {
  loanApplicationId: PropTypes.string,
  loanFormData: PropTypes.object.isRequired,
  currentState: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  isCommercialApp: PropTypes.bool,
  setIsEcgModalOpen: PropTypes.func.isRequired,
}

export default EcgToolbox
